import styled, { css } from 'styled-components'

import { BadgeCheckSVG } from "@icons"
import { injectFontStyle } from '@typography/helpers'
import { styleForMobile } from "@utils"

const OrderedList = styled.ol`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.sizes.s3};

    ${BadgeCheckSVG} {
      display: none;
    }

    li::marker {
      color: ${theme.colors.neutral70};
      ${injectFontStyle(theme, 'body1')}
    }

    ol {
      padding-top: ${theme.sizes.s3};
    }
  `}
`

const UnorderedArticleList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-left: ${theme.sizes.s7};
    row-gap: ${theme.sizes.s3};

    li {
      display: list-item;

      &::marker {
        color: ${theme.colors.primary40};
      }
      
      p {
        display: inline;
      }

      ul {
        padding: ${theme.sizes.s3} 0 0 ${theme.sizes.s7};
      }
    }
  `}
`

const UnorderedBadgeCheckList = styled.ul`
  ${({ theme }) => css`
    display: grid;
    list-style-position: none;
    margin: ${theme.sizes.s5} 0 0; 
    padding: 0;
    row-gap: ${theme.sizes.s4};

    ${styleForMobile} {
      margin: ${theme.sizes.s4} 0 0;
    }

    ${BadgeCheckSVG} {
      fill: ${theme.colors.primary40};
      max-height: 28px;
      max-width: 28px;
      min-height: 28px;
      min-width: 28px;
      padding-right: ${theme.sizes.s3};

      path {
        fill: ${theme.colors.primary40};
      }
    }

    li {
      display: flex;
    }
  `}
`

export { OrderedList, UnorderedArticleList, UnorderedBadgeCheckList }
