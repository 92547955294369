import {css} from "styled-components";
import ExternalLinkSVG from "@icons/ExternalLinkSVG";
import {Body2Bold, Body3Bold} from "@typography";
import {styleForMobile} from "@utils/MediaQueries";

const getCommonButtonStyle = ({ isLoading, small, theme }) => css`
  border-radius: ${theme.globals.borderRadius.bR3};
  padding: ${small
  ? `${theme.sizes.s3} ${theme.sizes.s4}`
  : `${theme.sizes.s12} ${theme.sizes.s6}`};
  transition:
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1),
    color 150ms cubic-bezier(0.4, 0, 0.2, 1),
    fill 150ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  span {
    display: flex;
    align-items: center;

    ${ExternalLinkSVG} * {
      fill: ${theme.colors.neutral0};
    }
  }

  ${styleForMobile} {
    padding: ${theme.sizes.s3} ${theme.sizes.s4};
  }

  ${small &&
isLoading &&
`
    box-shadow: none;
  `};

  ${Body2Bold}, ${Body3Bold} {
    ${isLoading &&
`
      visibility: hidden;
    `};
  }
`

export default getCommonButtonStyle