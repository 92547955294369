'use client'
/**
 * Layout component that wrap every template, inject third parties, minimal css and fonts.
 */

import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  useTransition,
} from 'react'
import InnerHTML from 'dangerously-set-html-content'
import concat from 'lodash/concat'
import get from 'lodash/get'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import HeadInjector from 'component-library/components/HeadInjector'
import ThemePartialProvider from 'component-library/components/ThemePartialProvider'
import PropTypes from 'prop-types'
// Todo: completely replace by Head API "Compared to react-helmet or other similar solutions, Gatsby Head is easier to use, more performant, has a smaller bundle size, and supports the latest React features."
import { Helmet } from '@react-helmet'
import { onINP } from 'web-vitals'

import Navigation from '@components/Navigation'
import getTheme from '@theme'
import SpinnerSection from '@templates/CustomPageTemplate/components/SpinnerSection'
import { getValueToDisplay } from '@utils/getValueToDisplay'
import { sitesConfig } from '@utils/Gatsby-node/siteArchitectureHelpers'
import { formatHreflangs } from '@utils/HeadInjector'
import { sanitizeTrailingSlash } from '@utils/sanitizeTrailingSlash'
import { setDomainCookie } from '@utils/setDomainCookie'
import { getSuffixByLocale } from '@utils/TranslationHelpers'

import Emails from './Emails'
import GlobalContext from './GlobalContext'
import {
  generateTemplateSeoSchemasJson,
  getGlobalSeoSchema,
  logos,
  spamTrap,
  svgIcons,
} from './layoutHelpers'
import DivFillAvailableSpace from './styles/DivFillAvailableSpace'
import './styles/layout.css'
import Favicon from 'favicon.ico'
const Footer = React.lazy(() => import('@components/Footer'))

// One trust groups ids are 1: essentials, 2: performance, 3: functionality, 4: targeting
// Todo: sinch groups have different names, so we need to make sure to harmonized that before switching.
// Todo: Since the datalayer event/triggers are created on GTM, we will also need to harmonized naming there
const useThirdPartiesStates = ({ isDevServer }) => {
  const [isAllowingPerformanceScript, setAllowingPerformanceScript] =
    useState(false)
  const [isAllowingFunctionalityScript, setAllowingFunctionalityScript] =
    useState(false)
  const [isAllowingTargetingScript, setAllowingTargetingScript] =
    useState(false)
  const [canInjectThirdParties, setCanInjectThirdParties] = useState(false)
  // Default group consent correspond to only accepting essential group (1)
  const initConsent = ',1,'
  const [lastConsent, setLastConsent] = useState(initConsent)
  const [consentProvided, setConsentProvided] = useState(false)

  const isDeniedOrGranted = (value) => (value ? 'granted' : 'denied')
  const [, startTransition] = useTransition()

  const refreshConsent = () => {
    startTransition(() => {
      const performanceGroupConsent =
        window?.OnetrustActiveGroups?.includes('2,')
      const functionalityGroupConsent =
        window?.OnetrustActiveGroups?.includes('3,')
      const targetingGroupConsent = window?.OnetrustActiveGroups?.includes('4,')

      setAllowingPerformanceScript(performanceGroupConsent)
      setAllowingFunctionalityScript(functionalityGroupConsent)
      setAllowingTargetingScript(targetingGroupConsent)
      // Update google consent if changed + actualOptanonConsent cookie used by MJ app
      if (lastConsent !== window?.OnetrustActiveGroups) {
        setLastConsent(window?.OnetrustActiveGroups)
        window.gtag('consent', 'update', {
          analytics_storage: isDeniedOrGranted(performanceGroupConsent),
          ad_storage: isDeniedOrGranted(targetingGroupConsent),
          functionality_storage: isDeniedOrGranted(functionalityGroupConsent),
          personalization_storage: isDeniedOrGranted(targetingGroupConsent),
          ad_user_data: isDeniedOrGranted(targetingGroupConsent),
          ad_personalization: isDeniedOrGranted(targetingGroupConsent),
        })
        const cookieValue = encodeURIComponent(window?.OnetrustActiveGroups)
        const cookieDuration = 365 * 86400 // One year in milliseconds
        // Share the cookie for all subdomain
        setDomainCookie('actualOptanonConsent', cookieValue, cookieDuration)
        setConsentProvided(true)
        // Push event confirming that the consent is up-to-date, other sites will use OneTrustGroupsUpdated event
        window.dataLayer.push({ event: 'consent-is-up-to-date' })
      }
    })
  }

  useEffect(() => {
    const enableClientSideFeats = () => {
      setCanInjectThirdParties(true)

      if (typeof gtag === 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.gtag = function () {
          window.dataLayer.push(arguments)
        }
      }
      // Set default consent at page load
      if (lastConsent === initConsent) {
        window.gtag('consent', 'default', {
          analytics_storage: 'denied',
          ad_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          wait_for_update: 500,
        })

        window.gtag('js', new Date())
        window.gtag('config', process.env.GATSBY_GA_ID)
      }

      refreshConsent()
    }
    // Inject third parties when browser is idle, using requestIdleCallback only if available
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(enableClientSideFeats, { timeout: 1000 })
    } else {
      enableClientSideFeats()
    }

    // Track INP
    const sendToGTM = (data) => {
      let debug = ''
      if (data.name === 'INP' && data.entries.length > 0) {
        debug = data.entries.map((entry) => ({
          attributionEntryType: entry?.entryType,
          startTime: entry?.startTime,
          duration: entry?.duration,
          processingStart: entry?.processingStart,
          processingEnd: entry?.processingEnd,
          cancelable: entry?.cancelable,
          target: entry?.target?.id,
          screenWidth: window?.innerWidth,
        }))
      }

      const poorestInteraction = maxBy(
        data?.entries,
        ({ duration }) => duration,
      )
      const poorestInteractionTarget = poorestInteraction?.target

      const webVitalsMeasurement = {
        name: data.name,
        id: data.id,
        value: data.value,
        rating: data.rating,
        debug: JSON.stringify(debug),
        debug_all: JSON.stringify(data),
        entries: data.entries,
        // Find entry with highest INP, send multiple data that can help identifying the problematic interaction element
        eventType: poorestInteraction?.name,
        target:
          poorestInteractionTarget?.id ||
          poorestInteractionTarget?.className ||
          poorestInteractionTarget?.parentNode?.className ||
          poorestInteractionTarget?.tagName +
            poorestInteractionTarget?.textContent,
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'coreWebVitals',
        coreWebVitalsEvent: data.name,
        webVitalsMeasurement,
      })
      // Add a way to display INP metric in prod without impact users
      if (window.location.search.includes('check-inp') || isDevServer) {
        console.log('webVitalsMeasurement:', webVitalsMeasurement)
        console.log('poorest interaction:', poorestInteraction)
        console.log('event type:', poorestInteraction?.name)
        console.log('poorest interaction element:', poorestInteractionTarget)
        console.log(
          'poorest interaction className:',
          poorestInteractionTarget?.className,
        )
        console.log(
          'poorest interaction tag and text',
          poorestInteractionTarget?.tagName +
            poorestInteractionTarget?.textContent,
        )
        console.log(
          'poorest interaction parent className:',
          poorestInteractionTarget?.parentNode?.className,
        )
      }
    }

    if (typeof window !== 'undefined') {
      onINP(sendToGTM)
    }
  }, [])

  return {
    canInjectThirdParties,
    consentProvided,
    isAllowingPerformanceScript,
    isAllowingFunctionalityScript,
    isAllowingTargetingScript,
    refreshConsent,
  }
}
const thirdPartyHTML = (script, partytown = false) => {
  return `<script type="text/${partytown ? 'partytown' : 'javascript'}">
     ${script}
  </script>`
}

// Helmet component
const HelmetThirdParties = ({
  hrefLangData,
  isBreadcrumbPages,
  isContactPages,
  pageContext,
  isHomePage,
  isMailgun,
}) => {
  const {
    isDevServer,
    isAllowingPerformanceScript,
    isAllowingFunctionalityScript,
    isAllowingTargetingScript,
    canInjectThirdParties,
    templateData,
    refreshConsent,
  } = useContext(GlobalContext)
  const {
    // When true VWO should not be injected
    automaticVWOImport,
    isNextServer,
    locale,
    siteConfig,
    ThirdPartyServices,
    seoData,
    VWOScript,
  } = pageContext
  const { QualifiedScript, Qualified, RudderAnalyticsScript } = {
    ...ThirdPartyServices,
  }

  const { seoSchema } = { ...seoData?.seoSchema }
  // Seo tags
  const seoTitle =
    getValueToDisplay(seoData, 'seoTitle', 'seodata', 'SeoTitle undefined') +
    ' | ' +
    siteConfig?.name
  const seoDescription = getValueToDisplay(
    seoData,
    'seoDescription.seoDescription',
    'seodata',
    'SeoDescription undefined',
  )
  const seoRobots = get(seoData, 'seoRobots')
  const seoCanonical = sanitizeTrailingSlash(
    getValueToDisplay(seoData, 'seoCanonical', 'seodata', '/'),
  )
  // Third parties consent
  const userAgent = canInjectThirdParties && window?.navigator.userAgent
  const isMobileADevice =
    canInjectThirdParties && /(iPhone|Android)/i.test(userAgent)
  const canInjectPerformanceScripts =
    canInjectThirdParties && isAllowingPerformanceScript
  // Actualize consent when the user first provide it
  if (canInjectThirdParties && !window.OptanonWrapper) {
    window.OptanonWrapper = refreshConsent
  }

  return (
    <Helmet defer={false}>
      <html lang={locale} />
      {!isNextServer && <link rel='icon' href={Favicon} />}
      {!isNextServer && (
        <link
          rel='icon'
          href={svgIcons[siteConfig.name]}
          type='image/svg+xml'
        />
      )}
      <link rel='apple-touch-icon' href={logos[siteConfig.name]} />
      <link rel='apple-touch-icon-precomposed' href={logos[siteConfig.name]} />
      <link rel='manifest' href={'/manifest.json'} />
      <title>{seoTitle}</title>
      <meta name='description' content={seoDescription} />
      <meta name='robots' content={seoRobots || 'index,follow'} />
      {!isDevServer && (
        <script type='text/javascript' id='js-blocker'>
          {/* Block all script on production mode in order to improve CWV score, then re-inject them on first client interaction */}
          {`
            // Provide an option using "no-opti" query param in order to disable it for testing purposes
            if(!window.location.search.includes('no-opti')) {
              const isNotBlocked = script => script.id === "js-blocker" || script?.src?.includes('app-') || script?.type?.includes("json")
              // Match script that are meant to be ran by partytown and partytown file itself
              const isPartytown = script => script.type.includes('partytown') || script?.textContent?.includes('partytown')
              // Create observe that will block other JS tags
              window.scriptObserver = new MutationObserver(mutations => {
                mutations.forEach(({ addedNodes }) => {
                  addedNodes.forEach(node => {
                    // For each added script tag
                    if(node.nodeType === 1 && node.tagName === 'SCRIPT') {
                      // Do not block this current script, same for app bundle so it does not edit the referrer
                      if(isNotBlocked(node)) return
                      // Differentiate partytown script from regular js
                      if(isPartytown(node)) return
                      
                      node.type = 'blocked/javascript'
                      const src = node.getAttribute("src")

                      if(src){
                        node.removeAttribute("src")
                        node.setAttribute("src-temp", src)
                      }
                    }
                  })
                })
              })

              window.scriptObserver.observe(document.documentElement, {
                childList: true,
                subtree: true
              })

              const eventsUserInteraction = [
                'scroll',
                'click',
                'mousemove',
                'keydown',
                'touchstart',
                'touchmove'
              ]
              const injectScript = script => {
                 // As for now, firefox is getting infinite reload on user interaction, let's investigate that later
                  if(navigator.userAgent.indexOf("Firefox/") > 0) return
                  const scriptNew = document.createElement('script')
                  scriptNew.type = 'text/javascript'
                  // Either re use the original script source or inject the original text as base64 format in src prop
                  if(script.getAttribute("src-temp")){
                    scriptNew.src = script.getAttribute("src-temp")
                  } else {
                    scriptNew.textContent = script.text
                  }
                  if(script.getAttribute('async') !== null){
                    scriptNew.async = true
                  }
                  // Remove original script from the DOM
                  script?.parentNode?.removeChild(script)
                  document?.body?.appendChild(scriptNew)
                }
              // Re-inject blocked script
              const reEnableScripts = script => {
                eventsUserInteraction.forEach(event => window.removeEventListener(event, reEnableScripts))

                

                let injectionDelay = 0
                const injectionInterval = 100
                document.querySelectorAll('script[type="blocked/javascript"]').forEach(async (script, index) => {
                  // Do not re-inject script that were not blocked
                  if(isNotBlocked(script)) return
                  // Load gatsby bundles as priority
                  if(script?.getAttribute("src-temp")?.slice(0, 1) === '/') {
                    injectScript(script)
                  }
                  // Delay other scripts
                  else {                  
                    await new Promise((res) => { res(setTimeout(() => {
                      injectScript(script)
                    }, injectionDelay += injectionInterval))})
                    // In order to not bloat the event loop, add a small delay between each script
                  }
                })
              }

              document.addEventListener("DOMContentLoaded", (event) => {
                // Remove observer
                if(window.scriptObserver) window.scriptObserver.disconnect()
                eventsUserInteraction.forEach(event => window.addEventListener(event, reEnableScripts), { once: true })
                const imgHeroSources = document.querySelectorAll('[data-img-onload]')
                // Inject src set to img tags once the page is fully loaded
                if(imgHeroSources) {
                  imgHeroSources.forEach(sourceTag => {
                    sourceTag.setAttribute('srcset', sourceTag.getAttribute('data-img-onload'))
                    sourceTag.parentElement.parentElement.setAttribute('data-is-loaded', true)
                  })
                }
              });
            }
          `}
        </script>
      )}
      <script type='text/partytown'>{`window.dataLayer = window.dataLayer || [];window.dataLayer.push({"platform":"gatsby"}); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${process.env.GATSBY_GOOGLE_TM_PRODUCTION}');`}</script>
      {!isDevServer && canInjectThirdParties && (
        <script
          src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
          type='text/javascript'
          data-domain-script={process.env.GATSBY_ONE_TRUST_ID}
          id='oneTrust-script'
        />
      )}
      {canInjectPerformanceScripts && <script>{RudderAnalyticsScript}</script>}
      {canInjectPerformanceScripts && !automaticVWOImport && (
        <script id='vwoCode'>{VWOScript}</script>
      )}
      {canInjectPerformanceScripts && !automaticVWOImport && (
        <script>
          {`
          window.rudderanalytics.ready(() => {
            window.rudderanalytics.page()
            console.log('Loading VWO integration...')
            // Initiate Rudderstack/VWO integration
            window.rudderanalytics.load(
              "${process.env.GATSBY_RUDDERSTACK_WRITE_KEY}",
              'https://mailgun-dataplane.rudderstack.com',
              {
                consentManagement: {
                  enabled: true,
                  provider: 'oneTrust'
                },
                integrations: {
                  VWO: {
                    loadIntegration: true,
                  },
                },
              }
            )
          })
          `}
        </script>
      )}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={seoCanonical} />
      <meta property='og:title' content={seoTitle} />
      <meta property='og:description' content={seoDescription} />
      <meta property='og:site_name' content={siteConfig?.name} />
      {/* seo social image optional */}
      {seoData?.seoImage && (
        <meta
          property='og:image'
          content={
            seoData?.seoImage?.gatsbyImageData?.images?.fallback?.src ||
            seoData?.url
          }
        />
      )}
      <meta name='twitter:title' content={seoTitle} />
      <meta name='twitter:description' content={seoDescription} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content={siteConfig?.twitterId} />
      {/* seo social image optional */}
      {seoData?.seoImage && (
        <meta
          name='twitter:image'
          content={
            seoData?.seoImage?.gatsbyImageData?.images?.fallback?.src ||
            seoData?.url
          }
        />
      )}
      {isHomePage && (
        <meta
          name='facebook-domain-verification'
          content='geer337ejphrdt5gxwnv1185ivkowc'
        />
      )}
      <link rel='canonical' href={seoCanonical} />
      {/* Basic seoSchema - describe company */}
      {/* Display seoSchema organization for home or contact page */}
      {(isHomePage || isContactPages) && (
        <script type='application/ld+json'>
          {getGlobalSeoSchema(siteConfig.name, locale)}
        </script>
      )}{' '}
      {/* Todo: inject in Hreflang injector? using Head API, if we decide to remove helmet */}
      {/* Seo schema manually set from seo config entry */}
      {seoSchema && <script type='application/ld+json'>{seoSchema}</script>}
      {/* Seo schema specific to the template */}
      {generateTemplateSeoSchemasJson({
        brandName: siteConfig.name,
        hrefLangData,
        templateData,
        isBreadcrumbPages,
        pageContext,
      })}
      {canInjectThirdParties &&
        !isMobileADevice &&
        isAllowingFunctionalityScript && (
          <InnerHTML html={thirdPartyHTML(Qualified, true)} />
        )}
      {!isDevServer &&
        canInjectThirdParties &&
        !isMobileADevice &&
        isAllowingFunctionalityScript && (
          // Qualified script is causing issues on dev mode and thus needs to be disabled.
          <script type='text/javascript' src={QualifiedScript} async />
        )}
      {
        // Pass the location parameters to the pardot iframe, Todo: see if it's not duplicating <Form/> iframe handler
        canInjectThirdParties && (
          <script type='text/javascript'>
            {(function () {
              const iframe = document.getElementById('myiframe')
              if (iframe?.src) iframe.src = iframe.src + window.location.search
            })()}
          </script>
        )
      }
      {canInjectThirdParties && isMailgun && isAllowingTargetingScript && (
        <script
          type='text/javascript'
          src='https://p.teads.tv/teads-fellow.js'
          async='true'
        />
      )}
      {canInjectThirdParties && isMailgun && isAllowingTargetingScript && (
        <script type='text/javascript'>
          {(function () {
            window.teads_e = window.teads_e || []
            window.teads_buyer_pixel_id = 9520
          })()}
        </script>
      )}
    </Helmet>
  )
}

const BottomThirdParties = ({ ThirdPartyServices, scripts }) => {
  const { PardotCDN } = ThirdPartyServices
  const { isAllowingTargetingScript, canInjectThirdParties } =
    useContext(GlobalContext)
  return (
    canInjectThirdParties && (
      <>
        {isAllowingTargetingScript && (
          <InnerHTML html={thirdPartyHTML(PardotCDN, true)} />
        )}
        {map(scripts, ({ text }) => (
          <InnerHTML html={text.internal.content} />
        ))}
      </>
    )
  )
}

const Layout = ({ children, data, location, pageContext }) => {
  const {
    alternativeTheme,
    // If true, will enable breadcrumb request and pass it to the context for Breadcrumb compo
    isBreadcrumbPages,
    limitLargeMenu = 1,
    locale,
    scripts,
    navigationMenuData,
    siteConfig,
    ThirdPartyServices,
  } = pageContext
  const isDevServer = location?.hostname?.includes('localhost')
  const isMailgun = siteConfig.name === 'Mailgun'
  const isSinch = siteConfig.name === 'Sinch'
  // Check if the page is a contact page with the name of the template used
  const isContactPages =
    get(pageContext, 'pageContent.additionalData.isContactPages') || false
  const isHomePage = getSuffixByLocale(locale) === location?.pathname
  const isLandingPage = pageContext?.isLandingPage || false
  const { breadcrumb: breadcrumbData } = { ...data?.sitePage }
  const { nodes: hrefLangsNodes } = { ...data?.allHreflangs }
  const { baseUrl } = pageContext?.siteConfig
  const hrefLangData = formatHreflangs(hrefLangsNodes, baseUrl)
  const thirdPartiesStates = useThirdPartiesStates({ isDevServer })
  const templateContent = (
    <>
      {/* Here for popups */}
      <div id='portal' />
      <DivFillAvailableSpace>
        <main>{children}</main>
      </DivFillAvailableSpace>
    </>
  )
  return (
    <GlobalContext.Provider
      value={{
        locale,
        location,
        navigationMenuData: concat(
          navigationMenuData.mainMenu,
          navigationMenuData.topMenu,
        ),
        siteConfig,
        breadcrumbData,
        alternativeTheme,
        templateData: data,
        isDevServer,
        isMailgun,
        isSinch,
        ...thirdPartiesStates,
      }}
    >
      <HeadInjector loadFonts={false} />
      <HelmetThirdParties
        pageContext={pageContext}
        isHomePage={isHomePage}
        isContactPages={isContactPages}
        isBreadcrumbPages={isBreadcrumbPages}
        hrefLangData={hrefLangData}
        isMailgun={isMailgun}
      />
      {!isLandingPage && (
        <Navigation
          hrefLangData={hrefLangData}
          location={location}
          navigationData={navigationMenuData}
          scrollLimit={limitLargeMenu}
          currentSiteBaseUrl={baseUrl}
        />
      )}
      {
        // Inject custom theme when provided
        alternativeTheme ? (
          <ThemePartialProvider theme={getTheme(sitesConfig[alternativeTheme])}>
            {templateContent}
          </ThemePartialProvider>
        ) : (
          templateContent
        )
      }

      <Emails data={spamTrap[siteConfig.name]} />

      {pageContext.footerMenuData && !isLandingPage && (
        <Suspense fallback={<SpinnerSection />}>
          <Footer footer={pageContext.footerMenuData} />
        </Suspense>
      )}
      <BottomThirdParties
        ThirdPartyServices={ThirdPartyServices}
        scripts={scripts}
      />
    </GlobalContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Layout
