import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { startsWith, trimEnd } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GlobalContext from '@components/Layout/GlobalContext'
import { sanitizeTrailingSlash } from '@utils'
import ArrowNextSVG from '@icons/ArrowNextSVG'
import ArrowPreviousSVG from '@icons/ArrowPreviousSVG'
import { getLinkStyle } from '@styles/getLinkStyle'

const StyledLinkComponent = ({
  children,
  isArrowPrev,
  isArrowNext,
  ...props
}) => {
  return (
    <Link isArrowNext={isArrowNext} isArrowPrev={isArrowPrev} {...props}>
      {isArrowPrev && <ArrowPreviousSVG />}
      {children}
      {isArrowNext && <ArrowNextSVG />}
    </Link>
  )
}

export const StyledLink = styled(StyledLinkComponent).attrs(
  ({ shouldSanitize = true, to }) => {
    // For links targeting page anchor, like /page_link#form
    const isAnchorLink = to && to.includes('#')
    // For links using query params, like /page_link?param=value
    const isQueryParam = to && to.includes('?')
    if (isAnchorLink || isQueryParam) {
      to = trimEnd(to, '/')
    }

    return {
      // The link needs to be sanitized when internal
      to:
        to &&
        to.slice(0, 1) === '/' &&
        shouldSanitize &&
        !isAnchorLink &&
        !isQueryParam
          ? sanitizeTrailingSlash(to)
          : to,
    }
  },
)`
  ${getLinkStyle}
`
// External links opens in a new tab
// baseUrl
const replace = (link, baseUrl) => {
  if (startsWith(link, baseUrl)) {
    const href = link?.replace(baseUrl, '')
    return { href }
  } else {
    return { href: link }
  }
}
const links = {
  'https://www.mailgun.com': (x, y) => replace(x, y),
  'https://www.mailjet.com': (x, y) => replace(x, y),
}
// Todo: might try exporting from a different file, potentially cause issue with styled components imports
export const ExternalLink = styled.a.attrs(({ href }) => {
  const siteContext = useContext(GlobalContext)
  const { baseUrl } = siteContext.siteConfig

  const externalLinksProps = {
    target: '_tabs',
    rel: 'noopener noreferrer',
  }

  if (links[baseUrl]) {
    return {
      ...links[baseUrl](href, baseUrl),
      ...externalLinksProps,
    }
  }
  return externalLinksProps
})`
  ${getLinkStyle}
`

StyledLink.propTypes = {
  underline: PropTypes.bool,
  isParagraphLink: PropTypes.bool,
  shouldSanitize: PropTypes.bool,
  theme: PropTypes.object,
  to: PropTypes.string,
}

ExternalLink.propTypes = {
  underline: PropTypes.bool,
  isParagraphLink: PropTypes.bool,
  theme: PropTypes.object,
  to: PropTypes.string,
}
