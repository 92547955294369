import styled, { css } from 'styled-components'
import Button from 'component-library/components/Button'

import ChevronSVG from '@icons/ChevronSVG'
import ExternalLinkSVG from '@icons/ExternalLinkSVG'
import { fadeInDownAnim } from '@styles/Animations'
import { OnlyDesktopAndTablet, OnlyMobile } from '@styles/DisplayOnMedia'
import { Body2, Caption1 } from '@typography'
import { styleForDesktopAndTablet, styleForMobile } from '@utils'

import { EarthSVG } from '../components'

export const StyledTopMenuButton = styled(Button)`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: ${theme.globals.borderRadius.bR3};
    box-shadow: none;
    cursor: pointer;
    display: flex;

    ${styleForDesktopAndTablet} {
      &:after {
        bottom: 0;
        border-bottom: solid ${theme.colors.primary50} ${theme.sizes.s1};
        content: '';
        display: block;
        position: absolute;
        transform: scaleX(0);
        transform-origin: 0% 50%;
        transition: transform 250ms ease-in-out;
        width: 100%;
      }

      ${ChevronSVG} {
        margin-left: ${theme.sizes.s2};
        * {
          fill: ${theme.colors.neutral40};
        }
      }

      ${EarthSVG} {
        margin-right: ${theme.sizes.s2};
        * {
          fill: ${theme.colors.neutral40};
        }
      }
    }

    ${OnlyDesktopAndTablet} > div {
      align-items: center;
      display: flex;
    }

    ${OnlyMobile} {
      width: 100%;

      & > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        svg {
          max-height: ${theme.sizes.s4};
          max-width: ${theme.sizes.s4};
          min-height: ${theme.sizes.s4};
          min-width: ${theme.sizes.s4};

          * {
            fill: ${theme.colors.neutral100};
            stroke: ${theme.colors.neutral100};
          }
        }
      }
    }

    &:hover:not(.is-loading):not(.is-disabled):not(:active) {
      background-color: transparent;
      box-shadow: none;

      ${styleForDesktopAndTablet} {
        border-radius: 0;

        ${ChevronSVG} * {
          fill: ${theme.colors.neutral50};
        }

        ${EarthSVG} * {
          fill: ${theme.colors.neutral50};
        }
      }

      ${styleForMobile} {
        background-color: ${theme.colors.neutral10};
        border-radius: ${theme.sizes.s3};

        svg * {
          fill: ${theme.colors.primary50};
          stroke: ${theme.colors.primary50};
        }
      }

      ${Body2}, ${Caption1} {
        color: ${theme.colors.neutral100};
        transition: color 0.25s;
      }
    }

    &:active:not(.is-loading):not(.is-disabled),
    &:disabled,
    &:focus:not(:active):not(.is-loading) {
      background-color: transparent;
      box-shadow: none;
    }

    ${styleForMobile} {
      width: 100%;
    }
  `}
`

export const TopMenu = styled.div`
  ${({
    isLarge,
    isOpen,
    selectedPanel = 'subpanel-mainMenu-999',
    theme,
  }) => css`
    background-color: ${theme.colors.neutral10};
    border-bottom: solid ${theme.colors.neutral20} ${theme.sizes.s0};
    height: ${isLarge ? `${theme.sizes.s6};` : `0px;`};
    position: fixed;
    top: 0;
    transition: height 250ms ease-out;
    width: 100%;
    z-index: ${isLarge ? 7001 : 4999};

    ${ExternalLinkSVG} {
      height: ${theme.sizes.s4};
      margin-left: ${theme.sizes.s2};
      width: ${theme.sizes.s4};

      & * {
        fill: ${theme.colors.neutral40};
      }
    }

    ${styleForDesktopAndTablet} {
      margin: 0 auto;

      .subpanel-${selectedPanel} {
        ${isOpen ? `display: flex;` : `display: none;`}
        ${fadeInDownAnim(isOpen)};
      }

      ${StyledTopMenuButton} {
        display: inline-flex;
        min-width: 0;
        padding: ${theme.sizes.s20} ${theme.sizes.s3};
      }

      ${isOpen &&
      `
        .menubutton-${selectedPanel} {
          border-radius: 0;

          &:after {
            transform: scaleX(1);
          }

          ${Caption1} {
            color: ${theme.colors.neutral100};
            transition: color 0.25s;
          }

          ${ChevronSVG} {
            transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);

            * {
              fill: ${theme.colors.neutral50};
            }
          }

          ${EarthSVG} * {
            fill: ${theme.colors.neutral50};
          }
        }
      `}
    }

    ${styleForMobile} {
      display: none;
      transition: none;
    }

    ${Caption1}, svg {
      opacity: ${isLarge ? `1;` : `0;`};
      transition: opacity 300ms ease-in;
    }
  `}
`
