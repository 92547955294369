import styled, { css } from 'styled-components'

import { withLink } from '@components/Button/withLink'
import Image from '@components/Image'
import ImageDiv from '@components/Image/styles/ImageDiv'
import ExternalLinkSVG from '@icons/ExternalLinkSVG'
import { StyledLink, ExternalLink } from '@styles/StyledLink'
import { Body2, Body3, Body3Bold } from '@typography'
import {
  styleForDesktop,
  styleForDesktopAndTablet,
  styleForMobile,
  styleForTabletAndMobile,
} from '@utils/MediaQueries'

// Button Main menu without link
export const MainMenuButton = styled.div`
  ${({ theme }) => css`
    align-items: center;
    column-gap: ${theme.sizes.s2};
    display: flex;
    padding: ${theme.sizes.s4} ${theme.sizes.s3};

    &:hover {
      cursor: pointer;
    }

    ${Body3Bold} {
      color: ${theme.colors.neutral70};
    }

    svg {
      max-height: ${theme.sizes.s4};
      max-width: ${theme.sizes.s4};
      min-height: ${theme.sizes.s4};
      min-width: ${theme.sizes.s4};
      transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);

      * {
        fill: ${theme.colors.neutral40};
        stroke: ${theme.colors.neutral40};
      }
    }

    ${styleForDesktop} {
      &:after {
        border-bottom: solid ${theme.colors.primary50} ${theme.sizes.s1};
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        transform: scaleX(0);
        transform-origin: 0% 50%;
        transition: transform 250ms ease-in-out;
        width: 100%;
      }

      &:hover {
        ${Body3Bold} {
          color: ${theme.colors.neutral100};
        }

        svg * {
          fill: ${theme.colors.neutral50};
          stroke: ${theme.colors.neutral50};
        }
      }
    }

    ${styleForTabletAndMobile} {
      padding: ${theme.sizes.s3} ${theme.sizes.s4};

      ${Body2} {
        color: ${theme.colors.neutral70};
      }

      svg * {
        fill: ${theme.colors.neutral100};
        stroke: ${theme.colors.neutral100};
      }

      &:hover {
        background-color: ${theme.colors.neutral10};
        border-radius: ${theme.sizes.s3};

        ${Body2} {
          color: ${theme.colors.neutral100};
        }

        svg * {
          fill: ${theme.colors.primary50};
          stroke: ${theme.colors.primary50};
        }
      }
    }
  `}
`

// Button Main menu with link
export const MainMenuButtonLink = withLink(styled(MainMenuButton)`
  ${styleForTabletAndMobile} {
    width: 100%;
  }
`)

// Correspond to the button with paragraph inside large dropdown
export const FirstButtonSubMenuWithParagraph = withLink(styled.div`
  ${({ hasLink = true, theme }) => css`
    border-radius: ${theme.globals.borderRadius.bR3};
    display: inline-flex;
    padding: ${theme.sizes.s3} ${theme.sizes.s4};

    ${styleForDesktop} {
      max-width: 232px;
      min-width: 232px;
    }

    ${styleForTabletAndMobile} {
      padding: ${theme.sizes.s3} ${theme.sizes.s4};
    }

    ${hasLink &&
    css`
      &:hover {
        background-color: ${theme.colors.neutral10};

        ${Body3} {
          color: ${theme.colors.neutral100};
        }

        ${Image} * {
          fill: ${theme.colors.primary50};
        }

        ${ExternalLinkSVG} * {
          fill: ${theme.colors.neutral35};
        }
      }

      &:active {
        ${ExternalLinkSVG} * {
          fill: ${theme.colors.neutral50};
        }
      }
    `}

    ${Body3} {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      max-width: 180px;
    }

    ${Image} {
      max-height: ${theme.sizes.s5};
      max-width: ${theme.sizes.s5};
      min-width: ${theme.sizes.s5};
      padding-right: ${theme.sizes.s3};

      * {
        fill: ${theme.colors.neutral100};
        max-height: ${theme.sizes.s5};
        max-width: ${theme.sizes.s5};
      }
    }
  `}
`)

// Correspond to a sub button of top menu dropdown
export const TopMenuSubButton = styled.li`
  ${({ theme }) => css`
    border-radius: ${theme.sizes.s3};

    &:hover {
      background-color: ${theme.colors.neutral10};

      ${Body2} {
        color: ${theme.colors.neutral100};
      }
    }

    ${StyledLink}, ${ExternalLink} {
      display: flex;
      padding: ${theme.sizes.s3} ${theme.sizes.s4};

      > * {
        display: flex;
        align-items: center;
      }

      &:hover {
        ${ExternalLinkSVG} * {
          fill: ${theme.colors.neutral35};
        }
      }

      ${styleForDesktopAndTablet} {
        white-space: nowrap;
      }
    }
  `}
`

// One column of the large dropdown panel
export const ColumnLargeDropdown = styled.li`
  ${({ theme }) => css`
    max-width: 264px;
    padding: 0 ${theme.sizes.s3};

    hr {
      color: ${theme.colors.neutral30};
      margin: ${theme.sizes.s3} 0;
    }

    & > ul:first-child {
      padding-left: 0;
    }

    ${styleForTabletAndMobile} {
      padding: 0;
    }
  `}
`

// Correspond to the buttons area below the buttons with paragraph inside the large dropdown
export const SubButtonArea = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;

  a {
    width: 100%;
  }
`

// Correspond to a button inside the SubButtonArea, in large dropdown
export const SubButtonLink = withLink(styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-radius: ${theme.globals.borderRadius.bR3};
    display: flex;
    padding: ${theme.sizes.s3} ${theme.sizes.s4};
    width: 100%;

    ${ExternalLinkSVG} {
      height: ${theme.sizes.s4};
      margin-left: ${theme.sizes.s2};
      width: ${theme.sizes.s4};

      & * {
        fill: ${theme.colors.neutral35};
      }
    }

    &:hover {
      background-color: ${theme.colors.neutral10};

      ${Body2} {
        color: ${theme.colors.neutral100};
      }

      ${Image} svg * {
        fill: ${theme.colors.primary50};
      }

      ${ExternalLinkSVG} * {
        fill: ${theme.colors.neutral35};
      }
    }

    &:active {
      ${ExternalLinkSVG} * {
        fill: ${theme.colors.neutral50};
      }
    }

    ${styleForTabletAndMobile} {
      padding: ${theme.sizes.s3} ${theme.sizes.s4};
    }

    ${Image} {
      margin-right: ${theme.sizes.s3};
      max-height: ${theme.sizes.s5};
      max-width: ${theme.sizes.s5};
      min-height: ${theme.sizes.s5};
      min-width: ${theme.sizes.s5};

      svg * {
        fill: ${theme.colors.neutral100};
      }
    }
  `}
`)

export const SubMenuCardText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.sizes.s4};

    ${styleForDesktop} {
      height: 100%;
      justify-content: space-between;
    }

    ${Body3Bold} {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-underline-offset: 3px;
    }

    // Underline text and svg
    & > div:last-child {
      align-items: center;
      display: flex;

      ${Body3Bold} {
        margin-right: ${theme.sizes.s3};
        text-decoration: underline;
        text-decoration-thickness: 1.5px;
      }
    }
  `}
`

// Correspond to the card inside large dropdown panel
export const SubMenuCard = styled.li`
  ${({ theme }) => css`
    max-width: 280px;

    ${Image} {
      min-width: 248px;

      img {
        height: 152px;
        object-fit: cover;
        transform: scale(1);
        transition: transform 250ms ease-in-out;
        width: 100%;
      }
    }

    ${ImageDiv} {
      border-radius: ${theme.sizes.s3};
      min-height: 152px;
    }

    ${StyledLink}, ${ExternalLink} {
      background-color: ${theme.colors.neutral10};
      border-radius: ${theme.sizes.s4};
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 264px;
      padding: ${theme.sizes.s4} ${theme.sizes.s4} ${theme.sizes.s5};
      row-gap: ${theme.sizes.s4};

      ${styleForDesktop} {
        min-height: 264px;
      }

      :hover {
        img {
          transform: scale(1.05);
        }

        ${SubMenuCardText} > div:last-child {
          ${Body3Bold} {
            color: ${theme.colors.secondary50};
          }
        }

        svg * {
          fill: ${theme.colors.secondary50};
        }
      }
    }

    svg * {
      fill: ${theme.colors.secondary60};
    }

    ${styleForTabletAndMobile} {
      height: max-content;
    }
  `}
`

// Todo: export a list of types (LITTLE_DROPDOWN, LARGE_DROPDOWN, TOP_MENU...)
// The general subpanel component for every submenu
export const SubPanelElement = styled.ul`
  ${({ type = null, theme }) => css`
    background-color: ${theme.colors.neutral0};
    border-radius: ${theme.sizes.s4};
    cursor: initial;
    display: none;
    flex-direction: column;
    margin: 0;
    text-align: start;

    li {
      list-style: none;
    }

    ${type === 'LITTLE_DROPDOWN' &&
    `
      a {
        width: 100%;
      }
    `}

    ${type === 'TOP_MENU'
      ? `padding: ${theme.sizes.s4};`
      : `padding: ${theme.sizes.s7} ${theme.sizes.s6};
    `}

    ${styleForDesktop} {
      box-shadow: 0 ${theme.sizes.s1} ${theme.sizes.s3} -${theme.sizes.s1} ${theme.colors.neutral100}29;
      position: absolute;
      top: ${theme.sizes.menuScrolledHeight};
      transition: top 250ms ease-out;

      ${type === 'LITTLE_DROPDOWN' &&
      `
        left: -44px;

        li {
          max-width: 248px;
          min-width: 248px;
        }
      `}

      ${type === 'LARGE_DROPDOWN' &&
      `
        border-radius: 0px;
        column-gap: 32px;
        flex-direction: row;
        justify-content: center;
        left: 0;
        position: fixed;
        top: ${theme.sizes.menuHeight};
        width: calc(100% - ${theme.sizes.s10});
      `}
    }

    ${styleForDesktopAndTablet} {
      ${type === 'TOP_MENU' &&
      `
        box-shadow: 0 ${theme.sizes.s1} ${theme.sizes.s3} -${theme.sizes.s1} ${theme.colors.neutral100}29;
        position: absolute;
        right: -${theme.sizes.s3};
        top: ${theme.sizes.s6};
      `}
    }

    ${styleForTabletAndMobile} {
      ${type === 'LITTLE_DROPDOWN' &&
      `
        padding: 0;
      `}

      ${type === 'LARGE_DROPDOWN' &&
      `
        padding: 0 ${theme.sizes.s3};
        row-gap: ${theme.sizes.s6};
        width: 264px;
      `}
    }

    ${styleForMobile} {
      ${type === 'TOP_MENU' &&
      `
        padding: 0;
      `}
    }
  `}
`
