import React, { useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@styles/Spinner'

import { StyledDiv } from './styles/StyledDiv'
import { Helmet } from 'react-helmet'

const CustomReactPlayer = React.lazy(() => import('./styles/CustomReactPlayer'))
const VideoPlayer = ({ videoProps }) => {
  const [playVideo, setPlayVideo] = useState(true)
  const defaultVideoOptions = {
    title: true,
    byline: true,
    portrait: true,
    playsinline: true,
    autopause: true,
    controls: true,
    playing: (videoProps?.isAutoPlay && playVideo) || false,
  }
  // ld-json is generated from
  const { seoVideoObject } = videoProps?.seoVideoObject || {}
  return (
    <StyledDiv onClick={() => setPlayVideo(!playVideo)}>
      {seoVideoObject?.length > 0 && (
        <Helmet>
          <script type='application/ld+json'>{seoVideoObject}</script>
        </Helmet>
      )}
      <Suspense fallback={<Spinner />}>
        <CustomReactPlayer
          width='100%'
          height='100%'
          {...defaultVideoOptions}
          {...videoProps}
        />
      </Suspense>
    </StyledDiv>
  )
}

VideoPlayer.propTypes = {
  videoProps: PropTypes.object,
}

export default VideoPlayer
