const { BLOCKS } = require('@contentful/rich-text-types')
const {
  renderRichText: originalRenderRichText,
} = require('gatsby-source-contentful/rich-text')
const { filter, isString, reduce } = require('lodash')

const stringToRichText = (string) => {
  const object = {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": string,
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  }

  return {
    raw: JSON.stringify(object)
  }
}

const defaultOptionRichText = () => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return children
    },
  },
})

const removeEmptyTag = (data) => {
  if (!data || (data.length === 1 && data[0] === '')) {
    return false
  }
  return filter(data, (val) => val !== '')
}

const addBreakPoint = (richText = {}, props = defaultOptionRichText()) => {
  if (!richText?.raw?.includes('\\\\n')) {
    return originalRenderRichText(richText, props)
  }

  const newRaw = richText?.raw.split('\\\\n').join('\\n') || undefined

  if (newRaw) {
    richText = {
      ...richText,
      raw: newRaw,
    }
  }
  return originalRenderRichText(richText, props)
}

const renderRichText = (text, props = null) => {
  // If text is a string/plain text, convert it to the contentful rich text format to be render
  const textToRender = isString(text) ? stringToRichText(text) : text

  const richtext =
    textToRender && textToRender.raw
      ? addBreakPoint(textToRender, props || defaultOptionRichText())
      : textToRender

  return reduce(
    richtext,
    (newArray, el) => {
      const children = el?.props?.children
      if (!Array.isArray(children)) {
        newArray.push(el)
      } else if (Array.isArray(children)) {
        const filteredArr = removeEmptyTag(children)
        if (filteredArr.length) {
          const newEl = {
            ...el,
            props: {
              ...el.props,
              children: filteredArr,
            },
          }
          newArray.push(newEl)
        }
      }
      return newArray
    },
    [],
  )
}

module.exports = {
  renderRichText,
}


