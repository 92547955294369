import React, { useContext } from 'react'
import { find, get, has, includes, map, split } from 'lodash'
import GlobalContext from '@components/Layout/GlobalContext'

import { sanitizeTrailingSlash } from '@utils/sanitizeTrailingSlash'
import { getSuffixByLocale } from '@utils/TranslationHelpers'
import MGIcon from '../../../static/svg/MailgunIcon.svg'
import MJIcon from '../../../static/svg/MailjetIcon.svg'
import { CONTENT_TYPES, findByContentType } from '@utils'

const mailgunEmails = [
  'deliv-team@mailgun.company',
  'outbound-sales@mailgun.company',
  'spamtrapmonitoring@mailgun.company',
  'iceman@mailgun.top',
  'admiral@mailgun.top',
  'timduncan@mailgun.top',
]

const mailjetEmails = [
  'deliv-team@mailjet.company',
  'outbound-sales@mailjet.company',
  'spamtrapmonitoring@mailjet.company',
  'pop@mailjet.top',
  'manu@mailjet.top',
  'tonyparker@mailjet.top',
]

export const spamTrap = {
  Mailgun: mailgunEmails,
  Mailjet: mailjetEmails,
}

export const svgIcons = {
  Mailjet: MJIcon,
  Mailgun: MGIcon,
}

export const logos = {
  Mailjet:
    'https://images.ctfassets.net/y6oq7udscnj8/34r8FUOtvd8lXdEDteTxSG/b38a5e52f36a4e5835d325bec79c4ddf/MJ-Icon.png',
  Mailgun:
    'https://images.ctfassets.net/y6oq7udscnj8/6bfhvqjWqiC8dCzBNHxtJP/d682492374473b2e0d1377f0d4247bda/MG-Icon.png',
}

export const seoSchemas = {
  mailgun: {
    'en-US': {
      sameAs: [
        'https://www.facebook.com/mailgun/',
        'https://twitter.com/Mail_Gun/',
        'https://www.linkedin.com/company/mailgun-inc/',
      ],
      url: `https://www.mailgun.com/`,
    },
  },
  mailjet: {
    'en-US': {
      sameAs: [
        'https://www.facebook.com/mailjet',
        'https://twitter.com/mailjet/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/',
    },
    'fr-FR': {
      sameAs: [
        'https://www.facebook.com/MailjetFrance/',
        'https://twitter.com/mailjet_fr/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/fr/',
    },
    'de-DE': {
      sameAs: [
        'https://www.facebook.com/MailjetDE',
        'https://twitter.com/mailjet_de/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/de/',
    },
    'es-ES': {
      sameAs: [
        'https://www.facebook.com/mailjetespanol/',
        'https://twitter.com/mailjet_es/',
        'https://www.linkedin.com/company/mailjet/',
      ],
      url: 'https://www.mailjet.com/es/',
    },
  },
}

export const getGlobalSeoSchema = (brandName = 'Mailjet', locale) =>
  JSON.stringify({
    // Only mailjet has located schema
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: brandName,
        logo: logos[brandName],
        legalName: brandName,
        ...seoSchemas[brandName?.toLowerCase()]?.[
          brandName === 'Mailjet' ? locale : 'en-US'
        ],
      },
      {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: seoSchemas[brandName?.toLowerCase()]?.[
          brandName === 'Mailjet' ? locale : 'en-US'
        ].url,
      },
    ],
  })

export const resourcesCategories = [
  'research reports',
  'guides & ebooks',
  'case studies',
  'integrations',
  'podcasts',
]

const isArticleFromBlog = (pageContext) => pageContext?.slug.includes('/blog/')

const isArticleFromResources = (pageContent) => {
  if (has(pageContent, 'content.categoryCodeId')) {
    return includes(resourcesCategories, pageContent.content.categoryCodeId)
  }
  return false
}

const getTitleFromResourcesArticle = ({ headerSection, title, titleHeader }) =>
  titleHeader || headerSection?.title || title || 'no title found'

const generateJsonldBreadcrumb = (hrefLangData, locale) => {
  const { breadcrumbData } = useContext(GlobalContext)
  const nativeUrlData = find(
    hrefLangData,
    (hrefLang) => hrefLang.hrefLang === locale,
  )
  const currentUrl = nativeUrlData && new URL(nativeUrlData.href)

  const itemListElementArray = map(breadcrumbData, ({ label, link }, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      name: label,
      item: currentUrl.origin + link,
    }
  })
  if (!itemListElementArray) return

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElementArray,
  }
}

export const generateTemplateSeoSchemasJson = ({
  pageContext,
  hrefLangData,
  brandName = 'Mailjet',
  templateData,
  isBreadcrumbPages,
}) => {
  // Find out if the page is corresponding to an article looking for isArticle in context or page content
  // This means that graphQL should include isArticle whether it's part of page generation or template query
  const isArticlePage =
    pageContext?.pageContent?.content?.isArticle ||
    JSON.stringify(templateData)?.includes('"isArticle":true')
  if (!isArticlePage && !isBreadcrumbPages) return

  const { pageContent, locale, slug } = pageContext
  const { content } = pageContent
  const jsonld = []
  if (isArticlePage) {
    const siteUrl = `https://www.${brandName.toLowerCase()}.com`
    // Blog Article is simply considered as an article with '/blog' in the slug
    if (isArticleFromBlog(pageContext)) {
      const { authors, date, headerSection } =
        templateData?.contentfulBlogArticle
      const headerSectionImage = findByContentType(
        headerSection?.any,
        CONTENT_TYPES.IMAGE,
      )
      const cardImage =
        headerSectionImage && 'https:' + headerSectionImage?.picture?.file?.url
      const languagePath = getSuffixByLocale(locale)
      const authorsJson = map(authors, (author) => ({
        '@type': 'Person',
        name: author?.secondItem?.title,
        url: sanitizeTrailingSlash(
          siteUrl +
            languagePath +
            'blog/author/' +
            split(author?.name?.toLowerCase(), ' author')[0].replace(/ /g, '-'),
        ),
      }))

      jsonld.push({
        '@context': 'https://schema.org',
        '@type': 'Article',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': sanitizeTrailingSlash(siteUrl + slug),
        },
        headline: headerSection?.title,
        // Handle multiple authors if available
        author: authorsJson,
        publisher: {
          '@type': 'Organization',
          name: brandName,
          logo: {
            '@type': 'ImageObject',
            url: logos[brandName],
          },
        },
        // Todo: see if we need to add time zone and hour
        dateModified: date,
        // Only include image if provided in card
        ...(cardImage ? { image: cardImage } : {}),
      })
    } else if (isArticleFromResources(pageContent)) {
      const author = get(content, 'authors[0].secondItem.title')
      const headline = getTitleFromResourcesArticle(content)
      const cardPicture = content?.card?.firstItem?.picture
      const cardImage = cardPicture && 'https:' + cardPicture?.file?.url
      const imageUrl = cardImage || logos[brandName]
      const date = get(content, 'date') || get(content, 'publishedAt')
      const datePublished = get(content, 'date') || get(content, 'createdAt')
      const dateModified = get(content, 'updatedAt')

      jsonld.push({
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline,
        image: [imageUrl],
        date,
        datePublished,
        dateModified,
        author: {
          '@type': author ? 'Person' : 'Organization',
          name: author || brandName,
        },
        publisher: {
          '@type': 'Organization',
          name: brandName,
          logo: {
            '@type': 'ImageObject',
            url: logos[brandName],
          },
        },
      })
    }
  }
  if (isBreadcrumbPages) {
    jsonld.push(generateJsonldBreadcrumb(hrefLangData, locale))
  }

  return (
    <script type='application/ld+json'>
      {jsonld.length > 1 ? JSON.stringify(jsonld) : JSON.stringify(jsonld[0])}
    </script>
  )
}
