import { mapValues } from 'lodash'
import mailjet from 'component-library/components/theme/mailjetTheme'

const getTypography = (colors) => {
  const { componentsFactory, globals } = mailjet
  const components = componentsFactory({ colors, globals })

  return {
    ...mapValues(components.typography, (value) => ({
      ...value,
      // Use this when needed to provide default value for each font
    })),
  }
}

export default getTypography
