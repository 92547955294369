import { css } from 'styled-components'
import ArrowNextSVG from '@icons/ArrowNextSVG'
import ArrowPreviousSVG from '@icons/ArrowPreviousSVG'

export const getLinkStyle = ({
  isArrowNext,
  isArrowPrev,
  isDisplayedInline = true,
  isParagraphLink,
  theme,
  underline,
}) => css`
  display: ${isDisplayedInline ? 'inline-flex' : 'inline'};
  color: ${theme.colors.grass40};
  ${(isArrowNext || isArrowPrev) &&
  css`
    display: flex;
    align-items: center;

    & > *,
    p {
      color: ${theme.colors.secondary60};
    }

    & > ${ArrowNextSVG} path,
    & > ${ArrowPreviousSVG} path {
      fill: ${theme.colors.secondary60};
    }

    & > ${ArrowNextSVG} {
      margin: 0px 0px 0px ${theme.sizes.s3};
    }

    & > ${ArrowPreviousSVG} {
      margin: 0px ${theme.sizes.s3} 0px 0px;
    }
  `}

  ${(underline || isParagraphLink) &&
  css`
    color: ${theme.colors.secondary60};
    *,
    p {
      color: ${theme.colors.secondary60};
    }

    & > svg path {
      fill: ${theme.colors.secondary60};
    }

    &:hover {
      color: ${theme.colors.secondary50};
      *,
      p {
        color: ${theme.colors.secondary50};
      }

      & > svg path {
        fill: ${theme.colors.secondary50};
      }
    }

    &:active {
      color: ${theme.colors.secondary70};
      *,
      p {
        color: ${theme.colors.secondary70};
      }

      & > svg path {
        fill: ${theme.colors.secondary70};
      }
    }

    &:focus {
      color: ${theme.colors.secondary60};
      *,
      p {
        color: ${theme.colors.secondary60};
      }

      & > svg path {
        fill: ${theme.colors.secondary60};
      }
    }
  `}

  &, & * , p {
    transition: color 0.25s;
    ${underline
      ? css`
          text-decoration: underline;
          text-underline-offset: 3px;
          text-decoration-thickness: ${theme.sizes.s1};
        `
      : `text-decoration: none;`}
  }
`
