import {
  Body1 as Body1Original,
  Body1Medium as Body1MediumOriginal,
  Body2 as Body2Original,
  Body2Medium as Body2MediumOriginal,
  Caption as CaptionOriginal,
  Header1 as Header1Original,
  Header2 as Header2Original,
  Header3 as Header3Original,
  Header4 as Header4Original,
} from 'component-library/components/Typography'
import styled from 'styled-components'
import {
  injectColor,
  injectDisplayInline,
  injectFontStyle,
  injectMobileStyle,
  injectUnderline,
} from './helpers'
// injectColors only needs custom parameter when using a paragraph color, bold and title use base color from the theme or some provided as a jsx parameter
const Body1 = styled(Body1Original)`
  ${({ theme }) => injectMobileStyle(theme, 'body1')}
  ${injectDisplayInline}
  ${injectUnderline}
  ${(props) => injectColor({ typoName: 'body1', ...props })}
`

const Body1Bold = styled(Body1MediumOriginal)`
  ${({ theme }) => injectFontStyle(theme, 'body1Bold')}
  ${({ theme }) => injectMobileStyle(theme, 'body1Bold')}
  ${injectDisplayInline}
  ${injectUnderline}
  ${injectColor}
`

const Body2 = styled(Body2Original)`
  ${({ theme }) => injectMobileStyle(theme, 'body2')}
  ${injectDisplayInline}
  ${injectUnderline}
  ${(props) => injectColor({ typoName: 'body2', ...props })}
`

const Body2Bold = styled(Body2MediumOriginal)`
  ${({ theme }) => injectFontStyle(theme, 'body2Bold')}
  ${({ theme }) => injectMobileStyle(theme, 'body2Bold')}
  ${injectDisplayInline}
  ${injectUnderline}
  ${injectColor}
`

const Body3 = styled.p`
  ${({ theme }) => injectFontStyle(theme, 'body3')}
  ${({ theme }) => injectMobileStyle(theme, 'body3')}
  ${(props) => injectColor({ typoName: 'body3', ...props })}
  ${injectDisplayInline}
  ${injectUnderline}
`

const Body3Bold = styled(Body3)`
  ${injectUnderline}
  ${injectColor}
  font-weight: ${({ theme }) => theme.fonts.body3Bold.fontWeight};
`

const Caption1 = styled(CaptionOriginal)`
  ${({ theme }) => injectFontStyle(theme, 'caption1')}
  ${({ theme }) => injectMobileStyle(theme, 'caption1')}
  ${injectUnderline}
  ${(props) => injectColor({ typoName: 'caption1', ...props })}
`

const Caption2 = styled.p`
  letter-spacing: 2.5px;
  text-transform: uppercase;
  ${({ theme }) => injectFontStyle(theme, 'caption2')}
  ${({ theme }) => injectMobileStyle(theme, 'caption2')}
  ${injectUnderline}
  ${(props) => injectColor({ typoName: 'caption2', ...props })}
`

const Header1 = styled(Header1Original)`
  ${({ theme }) => injectFontStyle(theme, 'header1')}
  ${({ theme }) => injectMobileStyle(theme, 'header1')}
  ${injectUnderline}
  ${injectColor}
`

const Header2 = styled(Header2Original)`
  ${({ theme }) => injectMobileStyle(theme, 'header2')}
  ${injectUnderline}
  ${injectColor}
`

const Header3 = styled(Header3Original)`
  ${({ theme }) => injectMobileStyle(theme, 'header3')}
  ${injectUnderline}
  ${injectColor}
`

const Header3Bold = styled(Header3Original)`
  ${({ theme }) => injectFontStyle(theme, 'header3Bold')}
  ${({ theme }) => injectMobileStyle(theme, 'header3Bold')}
  ${injectUnderline}
`

const Header4 = styled(Header4Original)`
  ${({ theme }) => injectMobileStyle(theme, 'header4')}
  ${injectUnderline}
  ${injectColor}
`

const Header4Bold = styled(Header4Original)`
  ${({ theme }) => injectFontStyle(theme, 'header4Bold')}
  ${({ theme }) => injectMobileStyle(theme, 'header4Bold')}
  ${injectUnderline}
`

const Header5Bold = styled.h3`
  ${({ theme }) => injectFontStyle(theme, 'header5Bold')}
  ${({ theme }) => injectMobileStyle(theme, 'header5Bold')}
  ${injectUnderline}
  ${injectColor}
`

const Header6 = styled.h3`
  ${({ theme }) => injectFontStyle(theme, 'header6')}
  ${({ theme }) => injectMobileStyle(theme, 'header6')}
  ${injectUnderline}
  ${injectColor}
`

const Monospace = styled.p`
  ${({ theme }) => injectFontStyle(theme, 'monospace')}
  ${({ theme }) => injectMobileStyle(theme, 'monospace')}
  ${injectUnderline}
  ${injectColor}
`

export {
  Body1,
  Body1Bold,
  Body2,
  Body2Bold,
  Body3,
  Body3Bold,
  Caption1,
  Caption2,
  Header1,
  Header2,
  Header3,
  Header3Bold,
  Header4,
  Header4Bold,
  Header5Bold,
  Header6,
  Monospace,
}
