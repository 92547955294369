import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import commonStyle from '../helpers/commonStyle'

const Body2Medium = styled.p.attrs(resetCSS)`
  ${commonStyle};
  color: ${({ color, theme }) =>
    color
      ? theme.colors[color] || color
      : theme.components.typography.body2Medium.text};
  font-family: ${({ theme }) => theme.fonts.body2Medium.fontFamily};
  font-size: ${({ theme }) => theme.fonts.body2Medium.fontSize};
  font-weight: ${({ theme }) => theme.fonts.body2Medium.fontWeight};
  line-height: ${({ theme }) => theme.fonts.body2Medium.lineHeight};
`

export default Body2Medium
